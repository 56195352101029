import imageHero from "./en-hero.png";

import imageFeatureMeet300 from "./en-features-community.png";
import imageFeatureMeet775 from "./en-features-community.png";
import imageFeatureMeet880 from "./en-features-community.png";

import imageFeatureGroup300 from "./en-features-discovery.png";
import imageFeatureGroup650 from "./en-features-discovery.png";
import imageFeatureGroup880 from "./en-features-discovery.png";

import imageFeatureShare300 from "./en-features-groups.png";
import imageFeatureShare703 from "./en-features-groups.png";
import imageFeatureShare880 from "./en-features-groups.png";

export default {
  header: {
    300: imageHero,
    434: imageHero,
    512: imageHero,
    599: imageHero,
    668: imageHero,
    864: imageHero,
    928: imageHero,
    946: imageHero,
  },
  featuresMeet: {
    300: imageFeatureMeet300,
    775: imageFeatureMeet775,
    880: imageFeatureMeet880,
  },
  featuresGroup: {
    300: imageFeatureGroup300,
    650: imageFeatureGroup650,
    880: imageFeatureGroup880,
  },
  featuresShare: {
    300: imageFeatureShare300,
    703: imageFeatureShare703,
    880: imageFeatureShare880,
  },
};
