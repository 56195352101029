import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import styles from "./Banner.module.scss";
import Button from "components/Button";
import { Link } from "react-router-dom";
import bannerMessage from "./../assets/banner-message.png";
import bannerMessageSingle from "./../assets/banner-message-2.webp";
import bannerLine from "./../assets/banner-line.webp";
import star from "./../assets/star.webp";
import { useIntl } from "react-intl";
import ButtonLink from "components/ButtonLink";
import useWebAppLink from "hooks/useWebAppLink";
import {
  goalHomepageOnboardingClick
} from "../../../helpers/goalTracking";

const Banner = ({ id, defaultMessage, description }: any) => {
  const intl = useIntl();
  const webAppUrl = useWebAppLink("/onboarding");

  const handleOnboardingClick = useCallback(() => {
    goalHomepageOnboardingClick();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.textContainer}>
          <h2 style={{ color: "white", textAlign: "center" }}>
            <FormattedMessage
              id={id}
              defaultMessage={defaultMessage}
              description={description}
            />
          </h2>
          <ButtonLink to={webAppUrl} onClick={handleOnboardingClick}>
            <FormattedMessage
              defaultMessage="Start Quiz"
              description="Start Quiz"
              id="pages.home.banner.quiz_cta"
            />
          </ButtonLink>
        </div>
      </div>
    </div>
  );
};

export default Banner;
