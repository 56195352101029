/* eslint-disable react/no-array-index-key */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import VisibilitySensor from "react-visibility-sensor";

import { ReactComponent as IconArrowPrev } from "../icons/arrow-prev.svg";
import { ReactComponent as IconArrowNext } from "../icons/arrow-next.svg";

export default class Carousel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    interval: PropTypes.number, // between slides
  };

  static defaultProps = {
    interval: 2500,
  };

  constructor(props) {
    super(props);

    this.state = { visible: false, hover: false };

    this._refs = [];
    this._idx = 0;
    this._timer = null;
  }

  componentWillUnmount() {
    this._stopScroll();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.visible !== this.state.visible ||
      prevState.hover !== this.state.hover
    ) {
      if (this.state.visible && !this.state.hover) {
        this._startScroll(0);
      } else {
        this._stopScroll();
      }
    }
  }

  _onVisibility(isVisible) {
    if (!!isVisible !== this.state.visible) {
      this.setState({ visible: !!isVisible });
    }
  }

  _stopScroll() {
    clearTimeout(this._timer);
    this._timer = null;
  }

  _startScroll(ms) {
    clearTimeout(this._timer);
    this._timer = setTimeout(this._doScroll.bind(this, 1), ms);
  }

  _doScroll(delta) {
    clearTimeout(this._timer);
    this._idx = (this._idx + delta + this._refs.length) % this._refs.length;

    this._refs[this._idx].current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    });

    this._startScroll(this.props.interval);
  }

  render() {
    const n = React.Children.count(this.props.children);

    this._refs = [];
    for (let idx = 0; idx < n; idx++) {
      this._refs.push(React.createRef());
    }

    return (
      <VisibilitySensor onChange={this._onVisibility.bind(this)}>
        <div
          className={classnames("Carousel", this.props.className)}
          onTouchStart={() => this.setState({ hover: true })}
          onTouchEnd={() => this.setState({ hover: false })}
          onMouseEnter={() => this.setState({ hover: true })}
          onMouseLeave={() => this.setState({ hover: false })}
        >
          <div
            className="Carousel-btn Carousel-btn--prev"
            onClick={this._doScroll.bind(this, -1)}
          >
            <IconArrowPrev />
          </div>

          <div className="Carousel-container">
            <div className="Carousel-slides">
              {React.Children.map(this.props.children, (c, idx) => (
                <div className="Carousel-slide" ref={this._refs[idx]} key={idx}>
                  {c}
                </div>
              ))}
            </div>
          </div>

          <div
            className="Carousel-btn Carousel-btn--next"
            onClick={this._doScroll.bind(this, 1)}
          >
            <IconArrowNext />
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}
