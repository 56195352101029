import { CommunityPost } from "types/CommunityPost";
import { transformCommunityPost } from "helpers/dataTransformation";
import useSWR from "swr";
import axios from "helpers/axios";
import defaultSWROptions from "helpers/defaultSWROptions";

export interface Props {
  categoryCode?: string;
  excludedUids?: string[];
  limit?: number;
  groupId?: string;
  withTags?: number[];
  locale?: string;
  uids?: string[];
  topicId?: number;
  embedded?: boolean;
  page?: number;
}

export const API_PATH = `/api/web/posts`;

const fetcher = ({ url, params }: { url: string; params: any }) =>
  axios
    .get(url, {
      params,
    })
    .then((res) => res.data.posts?.map(transformCommunityPost));

const useCommunityPosts = (props: Props) => {
  const params = {
    category_code: props.categoryCode,
    excluded_uids: props.excludedUids,
    limit: props.uids?.length || props.limit,
    pages_group_uid: props.groupId,
    with_tags: props.withTags,
    locale: props.locale,
    uids: props.uids,
    topic_id: props.topicId,
    embedded: props.embedded,
    page: props.page || 1,
  };

  const { data, error, isLoading } = useSWR<CommunityPost[]>(
    {
      url: API_PATH,
      params,
    },
    fetcher,
    defaultSWROptions
  );

  return {
    data,
    error,
    loading: isLoading,
  };
};

export type UseCommunityPostsReturnType = ReturnType<typeof useCommunityPosts>;

export default useCommunityPosts;
