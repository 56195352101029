import imageLogoBBC from "./assets/logo-bbc.webp";

import imageLogoFortune from "./assets/logo-fortune.webp";
import imageLogoNYT from "./assets/logo-nyt.webp";

import imageLogoTimes from "./assets/logo-times.webp";
import imageLogoVogue from "./assets/logo-vogue.webp";
import imageLogoWWDC17 from "./assets/logo-wwdc17.webp";

export const imageMapping = [
  { src: imageLogoWWDC17, alt: "WWDC17", className: "logoWwdc17" },
  { src: imageLogoNYT, alt: "The New York Times", className: "logoNyt" },
  { src: imageLogoFortune, alt: "Fortune", className: "logoFortune" },
  { src: imageLogoBBC, alt: "BBC", className: "logoBbc" },
  { src: imageLogoTimes, alt: "The Times", className: "logoTimes" },
  { src: imageLogoVogue, alt: "Vogue", className: "logoVogue" },
];
