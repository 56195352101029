import React from "react";
import classnames from "classnames";

export interface Props {
  secondary?: boolean;
  className?: string;
  title: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
}

function PageTitle({ title, secondary, className, subtitle }: Props) {
  const combinedClassName = classnames(
    "PageTitle",
    secondary ? "PageTitle--secondary" : "PageTitle--primary",
    className
  );

  const titleComponent = secondary ? (
    <h2 className={combinedClassName}>{title}</h2>
  ) : (
    <h1 className={combinedClassName}>{title}</h1>
  );

  return (
    <>
      {title && <>{titleComponent}</>}
      {subtitle && <p className="PageTitle-subtitle">{subtitle}</p>}
    </>
  );
}

export default PageTitle;
